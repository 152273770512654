import { createStore } from 'vuex';
import { getAllInvoices } from './api';

export default createStore({
  state: {
    invoices: [],
    isLoading: false,
    error: null,
  },
  mutations: {
    setInvoices(state, invoices) {
      state.invoices = invoices;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    async fetchInvoices({ commit }, email) {
      commit('setLoading', true);
      commit('setError', null);
      try {
        const invoices = await getAllInvoices(email);
        commit('setInvoices', invoices);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('setLoading', false);
      }
    },
  },
  getters: {
    getInvoices: (state) => state.invoices,
    getLoadingStatus: (state) => state.isLoading,
    getError: (state) => state.error,
  },
});
