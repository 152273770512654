<template>
  <div class="account-details container mt-5">
    <h1 class="mb-4">Szczegóły konta</h1>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ user.company }}</h5>
        <p class="card-text">Email: {{ user.email }}</p>
        <p class="card-text">NIP: {{ user.nip }}</p>
        <!-- Dodaj więcej szczegółów konta w miarę potrzeb -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDetails',
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')) || {} // Pobieranie danych użytkownika z localStorage
    };
  }
};
</script>

<style scoped>
.account-details {
  max-width: 600px;
  margin: auto;
}
</style>
