<template>
  <div class="home">
    <InvoiceList />
  </div>
</template>

<script>
import InvoiceList from '../components/InvoiceList.vue';

export default {
  components: {
    InvoiceList,
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex: 1;
  height: 100%;
}
</style>
