<template>
  <div id="app">
    <AppHeader v-if="isLoggedIn" :user="user" :remainingTime="formattedTime" />
    <div class="main-layout" v-if="isLoggedIn">
      <AppSidebar />
      <div class="content">
        <router-view />
      </div>
    </div>
    <router-view v-else />
    <AppFooter v-if="isLoggedIn" />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import AppSidebar from './components/AppSidebar.vue';
import EventBus from './bus';

export default {
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      isLoggedIn: false,
      user: null,
      remainingTime: 0,
    };
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  created() {
    EventBus.on('login', this.handleLogin);
    EventBus.on('logout', this.handleLogout);
    this.checkSession();
  },
  methods: {
    checkSession() {
      const expirationTime = localStorage.getItem('logoutTime');
      if (expirationTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime >= expirationTime) {
          this.handleLogout();
        } else {
          this.remainingTime = expirationTime - currentTime;
          this.startLogoutTimer();
          this.isLoggedIn = true;
          this.user = JSON.parse(localStorage.getItem('user'));
        }
      }
    },
    handleLogin(user) {
      this.isLoggedIn = true;
      this.user = user;
      this.resetLogoutTimer();
    },
    handleLogout() {
      console.log('Handling logout event');
      this.isLoggedIn = false;
      this.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('logoutTime');
      document.cookie = "loggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      clearInterval(this.logoutInterval);
      this.$router.push('/login');
    },
    startLogoutTimer() {
      this.logoutInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--;
        } else {
          this.handleLogout();
        }
      }, 1000);
    },
    resetLogoutTimer() {
      this.remainingTime = 20 * 60; // 20 minut
      const logoutTime = Math.floor(Date.now() / 1000) + this.remainingTime;
      localStorage.setItem('logoutTime', logoutTime);
      this.startLogoutTimer();
    }
  },
  beforeUnmount() {
    EventBus.off('login', this.handleLogin);
    EventBus.off('logout', this.handleLogout);
    clearInterval(this.logoutInterval);
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
.main-layout {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
}
.content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
</style>