import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import the Vuex store

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue-3';

import EventBus from './bus';

const app = createApp(App);
app.config.globalProperties.$eventBus = EventBus;
app.use(router);
app.use(BootstrapVue);
app.use(store); // Use the Vuex store

// Sprawdź, czy użytkownik jest zalogowany
const user = localStorage.getItem('user');
if (user) {
  app.config.globalProperties.$user = JSON.parse(user);
} else {
  app.config.globalProperties.$user = null;
}

app.mount('#app');