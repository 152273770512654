<template>
  <aside class="sidebar">
    <h3 class="menu-title">Menu</h3>
    <ul class="menu-list">
      <li class="menu-element">
        <router-link to="/" class="menu-link"><i class="fas fa-file-invoice me-2"></i>Lista faktur</router-link>
      </li>
      <li class="menu-element">
        <router-link to="/account-details" class="menu-link"><i class="fas fa-user fa-lg me-2"></i>Moje konto</router-link>
      </li>
      <!-- Możesz dodać więcej opcji menu tutaj -->
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'AppSidebar',
};
</script>

<style scoped>
.sidebar {
  width: 200px;
  background-color: #3b3f44;
  color: #fff;
  padding: 20px;
}

.menu-title {
  padding: 10px;
  font-size: 1.5rem;
  border-bottom: 1px solid #575b61;
  margin-bottom: 20px;
}

.menu-list {
  list-style-type: none;
  padding: 0;
}

.menu-element {
  margin-bottom: 15px;
}

.menu-link {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.menu-link:hover {
  color: #fff;
  background-color: #575b61;
}
</style>
