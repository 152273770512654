<template>
  <header class="header d-flex justify-content-between align-items-center p-3 bg-light">
    <div style="padding-left:20px;" class="left-header d-flex align-items-center">
      <img src="/tayma.png" alt="Logo" class="logo me-2" />
      <span style="margin-left:10px;" class="project-name h4 mb-0">Faktury Tayma</span>
    </div>

    <div class="d-flex align-items-center">
      <span class="me-4 session-timer">Sesja wygaśnie za: <strong>{{ remainingTime }}</strong></span>
      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle profile-element" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          <i style="margin-right: 5px;" class="fas fa-user fa-lg me-2"></i>{{ user.company }}
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
          <li><router-link class="dropdown-item" to="/account-details"><i class="fas fa-info-circle me-2"></i> Szczegóły konta</router-link></li>
          <li><a class="dropdown-item" @click="logout"><i class="fas fa-sign-out-alt me-2"></i> Wyloguj</a></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import EventBus from '@/bus';

export default {
  name: 'AppHeader',
  props: {
    user: {
      type: Object,
      required: true
    },
    remainingTime: {
      type: String, // Przyjmujemy sformatowany czas jako string
      required: true
    }
  },
  methods: {
    logout() {
      EventBus.emit('logout');
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.header {
  background-color: #f8f9fa;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 50px;
}
.project-name {
  font-size: 1.5em;
  font-weight: bold;
}
.session-timer {
  font-size: 1em;
  color: #6c757d;
}
.profile-element {
  display: flex;
  align-items: center;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.profile-element:hover {
  background-color: #0056b3;
}
</style>
