import axios from 'axios';

// Podstawowa konfiguracja Axios
const apiClient = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
}

export const getAllOrdersIds = async (email) => {
  let fromTime = Math.floor(new Date().getTime() / 1000) - 15778458 ; // 6 miesięcy w sekundach
    let latestOrderTime = 0
    const currentTime = Math.floor(new Date().getTime() / 1000); // Aktualny czas w sekundach
    let last_orders = [];
    let iteration = 1
    while (last_orders) {
        let pack = await getPackOfOrdersIds(email, currentTime - fromTime);
        if (pack.length === 0) break; // Jeśli pakiet jest pusty, przerywamy pętlę
        for (let order of pack) {
            // Tylko zamówienia, które spełniają wszystkie poniższe warunki, są dodawane
            last_orders.push(order);
        }

        // Aktualizacja 'fromTime' na podstawie ostatniego zamówienia w pakiecie
        latestOrderTime = Math.max(...pack.map(o => o.date_confirmed));
        
        if (latestOrderTime > fromTime) {
            fromTime = latestOrderTime;
        } else {
            // Jeśli nie ma nowszych zamówień, zakończ pętlę
            break;
        }

        console.log(`Pobrano stronę ${iteration}, ostatnie zamówienie z: ${latestOrderTime}, zamówienie: ${pack[pack.length-1].order_id}`)
        iteration++
    }

    return last_orders;
};

export const getPackOfOrdersIds = async (email, dateFrom) => {
  const parameters = {
    filter_email: email,
    date_from: dateFrom,
    sort_order: 'DESC',
  };

  const data = new URLSearchParams({
    method: 'getOrders',
    parameters: JSON.stringify(parameters)
  }).toString()

  try {
    const response = await apiClient.post('/connector.php', data.toString());
    if (response.data && response.data.orders) {
      return response.data.orders.map(order => ({
        order_id: order.order_id,
        shop_order_id: order.shop_order_id,
        date_add: order.date_add
      }));
    } else {
      console.error('Response does not contain orders:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Błąd podczas pobierania zamówień:', error);
    throw error;
  }
};

export const getOrdersIds = async (email, dateFrom, lastOrderId = null) => {
  const parameters = {
    filter_email: email,
    date_from: dateFrom,
    sort_order: 'DESC',
  };

  if (lastOrderId) {
    parameters.id_from = lastOrderId;
  }

  const data = new URLSearchParams({
    method: 'getOrders',
    parameters: JSON.stringify(parameters).replaceAll(':', ':+')
  }).toString().replaceAll('%2B', '+');

  try {
    const response = await apiClient.post('/connector.php', data.toString());
    if (response.data && response.data.orders) {
      return response.data.orders.map(order => ({
        order_id: order.order_id,
        shop_order_id: order.shop_order_id,
        date_add: order.date_add
      }));
    } else {
      console.error('Response does not contain orders:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Błąd podczas pobierania zamówień:', error);
    throw error;
  }
};

export const getInvoicesForOrder = async (order) => {
  const data = new URLSearchParams({
    method: 'getInvoices',
    parameters: JSON.stringify({
      order_id: order.order_id
    }).replaceAll(':', ':+')
  }).toString().replaceAll('%2B', '+');

  try {
    const response = await apiClient.post('/connector.php', data.toString(), { timeout: 30000 });
    if (response.data && response.data.invoices) {
      return response.data.invoices.map(invoice => ({
        id: invoice.invoice_id,
        number: invoice.number,
        issueDate: formatDate(invoice.date_add),
        dueDate: invoice.date_pay_to > 0 ? formatDate(invoice.date_pay_to) : formatDate(invoice.date_add),
        totalAmount: `${invoice.total_price_brutto} PLN`,
        company: invoice.invoice_company,
        fullname: invoice.invoice_fullname,
        nip: invoice.invoice_nip,
        postcode: invoice.invoice_postcode,
        address: invoice.invoice_address,
        city: invoice.invoice_city,
        seller: invoice.seller,
        type: invoice.type === 'normal' ? 'Faktura' : 'Korekta',
        items: invoice.items.map(item => ({
          name: item.name,
          price_brutto: item.price_brutto,
          price_netto: item.price_netto,
          tax_rate: item.tax_rate,
          quantity: item.quantity,
        })),
        order_id: order.order_id,
        shop_order_id: order.shop_order_id
      }));
    } else {
      console.error('Response does not contain invoices:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Błąd podczas pobierania faktur:', error);
    throw error;
  }
};

export const downloadInvoiceFile = async (invoice) => {
  const data = new URLSearchParams({
    'method': 'getInvoiceFile',
    'parameters': JSON.stringify({
      'invoice_id': invoice.id
    }).replaceAll(':', ':+')
  }).toString().replaceAll('%2B', '+');

  try {
    const response = await apiClient.post('/connector.php', data.toString());

    const base64String = response.data.invoice.replace(/^data:/, '');
    const binaryString = window.atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${response.data.invoice_number}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Błąd podczas pobierania faktur:', error);
    throw error;
  }
};