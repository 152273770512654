<template>
  <div class="invoice-list">
    <h1 class="title">Faktury</h1>
    <button class="btn-refresh btn btn-outline-info" data-bs-dismiss="modal" @click="refreshInvoices">Odśwież faktury</button>
    <div class="filters">
      <div class="filter-item">
        <label for="dateFilter">Sortuj według daty:</label>
        <select v-model="dateFilter" @change="applyFilter">
          <option value="newest">Od najnowszego do najstarszego</option>
          <option value="oldest">Od najstarszego do najnowszego</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="paidFilter">Filtruj według statusu:</label>
        <select v-model="paidFilter" @change="applyFilter">
          <option value="all">Wszystko</option>
          <option value="paid">Opłacone</option>
          <option value="unpaid">Nieopłacone</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="overdueFilter">Filtruj według terminu:</label>
        <select v-model="overdueFilter" @change="applyFilter">
          <option value="all">Wszystko</option>
          <option value="overdue">Po terminie</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="invoiceType">Typ:</label>
        <select v-model="invoiceType" @change="applyFilter">
          <option value="all">Wszystko</option>
          <option value="faktura">Faktura</option>
          <option value="korekta">Korekta</option>
        </select>
      </div>
      <div class="filter-item">
        <label for="orderSearch">Szukaj po ID zamówienia:</label>
        <input v-model="orderSearch" @input="applyFilter" placeholder="Wprowadź ID">
      </div>
      <div class="filter-item">
        <label for="numberSearch">Szukaj po numerze faktury:</label>
        <input v-model="numberSearch" @input="applyFilter" placeholder="Wprowadź numer">
      </div>
    </div>
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
    </div>
    <div v-else-if="error" class="error">
      <p>{{ error }}</p>
    </div>
    <div v-else class="table-container">
      <p >Załadowano faktury z {{ loadedOrderCount }} na {{ totalOrderCount }} zamówień.</p>
      <p >Wyświetlane faktury: {{ paginatedInvoices.length }} z {{ Object.values(displayedInvoices).length }}.</p>
      <table class="table">
        <thead>
          <tr>
            <th>ID Zamówienia</th>
            <th>Typ</th>
            <th>Numer faktury</th>
            <th>Data wystawienia</th>
            <th>Termin zapłaty</th>
            <th>Kwota brutto</th>
            <th>Status</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in paginatedInvoices" :key="element.id" :class="{ overdue: isOverdue(element.invoice) }">
            <td>{{ element.id }}</td>
            <td>{{ element.invoice.type }}</td>
            <td>{{ element.invoice.number }}</td>
            <td>{{ element.invoice.issueDate }}</td>
            <td>
              <span>{{ element.invoice.dueDate }}</span>
              <i v-if="isOverdue(element.invoice)" class="fas fa-exclamation-triangle overdue-icon" title="Termin faktury już minął, jeśli opłaciłeś tę fakturę oznacz ją jako opłaconą"></i>
            </td>
            <td>{{ element.invoice.totalAmount }}</td>
            <td>
              <select v-model="element.invoice.isPaid" @change="updateInvoiceStatus(element)" :class="{'paid': element.invoice.isPaid, 'unpaid': !element.invoice.isPaid}" :disabled="element.invoice.type === 'Korekta'">
                <option :value="true">Opłacone</option>
                <option :value="false">Nieopłacone</option>
              </select>
            </td>
            <td>
              <button class="btn-details" @click="viewDetails(element.invoice)">Szczegóły</button>
              <button class="btn-download" @click="downloadInvoice(element)">
                <i class="fas fa-download"></i> Pobierz
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="loadMoreTimer > 0" class="timer">
      <p>Proszę czekać {{ loadMoreTimer }} sekund przed załadowaniem większej ilości faktur.</p>
    </div>
    <button @click="loadMoreInvoices" class="btn btn-outline-secondary" :disabled="isLoading || loadMoreTimer > 0">
      Załaduj więcej faktur
    </button>

    <!-- Modal -->
    <div class="modal fade" id="invoiceModal" tabindex="-1" aria-labelledby="invoiceModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="invoiceModalLabel">Szczegóły faktury</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="selectedInvoice">
            <h5>Dane kupującego</h5>
            <p><strong>Numer faktury:</strong> {{ selectedInvoice.number }}</p>
            <p v-if="selectedInvoice.fullname && selectedInvoice.fullname.length > 0"><strong>Imię i nazwisko:</strong> {{ selectedInvoice.fullname }}</p>
            <p v-if="selectedInvoice.company && selectedInvoice.company.length > 0"><strong>Firma:</strong> {{ selectedInvoice.company }}</p>
            <p v-if="selectedInvoice.nip && selectedInvoice.nip.length > 0"><strong>NIP:</strong> {{ selectedInvoice.nip }}</p>
            <p v-if="selectedInvoice.city && selectedInvoice.city.length > 0"><strong>Adres:</strong> {{ selectedInvoice.postcode + " " + selectedInvoice.city + ", " + selectedInvoice.address }}</p>
            <p><strong>Data wystawienia:</strong> {{ selectedInvoice.issueDate }}</p>
            <p><strong>Termin zapłaty:</strong> {{ selectedInvoice.dueDate }}</p>
            <p><strong>Kwota brutto:</strong> {{ parseFloat(parseFloat(selectedInvoice.totalAmount).toFixed(2)) }}</p>
            <h5 style="margin-top:50px">Sprzedawca</h5>
            <p><strong>Numer faktury:</strong> {{ selectedInvoice.seller }}</p>

            <h5 style="margin-top:50px">Produkty</h5>
            <table class="table">
              <thead>
                <tr>
                  <th>Nazwa produktu</th>
                  <th>Ilość</th>
                  <th>Cena netto</th>
                  <th>Cena brutto</th>
                  <th>VAT</th>
                  <th>Wartość brutto</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in selectedInvoice.items" :key="product.id">
                  <td>{{ product.name }}</td>
                  <td>{{ product.quantity }}</td>
                  <td>{{ parseFloat(parseFloat(product.price_netto).toFixed(2)) }}</td>
                  <td>{{ parseFloat(parseFloat(product.price_brutto).toFixed(2)) }}</td>
                  <td>{{ product.tax_rate }}</td>
                  <td>{{ parseFloat(parseFloat(product.price_brutto * product.quantity).toFixed(2)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import EventBus from '@/bus';

export default {
  name: 'InvoiceList',
  data() {
    return {
      paginatedInvoices: [],
      displayedInvoices: {},
      dateFilter: 'newest',
      paidFilter: 'all',
      overdueFilter: 'all',
      invoiceType: 'all',
      orderSearch: '',
      numberSearch: '',
      loadMoreTimer: 0,
      lastOrderDate: Math.floor(Date.now() / 1000) - 6 * 30 * 24 * 60 * 60, // 6 months ago
      lastOrderId: null,
      isLoading: false,
      error: '',
      selectedInvoice: null,
      totalOrderCount: localStorage.getItem('totalOrderCount'),
      loadedOrderCount: localStorage.getItem('loadedOrderCount') || 0,
      orderIDs: []
    };
  },
  methods: {
    async fetchOrders(email) {
      try {
        const response = await axios.get(`https://fakturytayma.pl:3000/api/invoices/orders/${email}`);
        const orders = response.data;
        let storedInvoices = JSON.parse(localStorage.getItem('invoices')) || {};
        for (let order of orders) {
          storedInvoices[order.order_id] = {
            id: order.shop_order_id
          };
        }
        localStorage.setItem('totalOrderCount', orders.length);
        localStorage.setItem('invoices', JSON.stringify(storedInvoices));
        this.orderIDs = [...this.orderIDs, ...orders.map(order => order.order_id)];
        return orders;
      } catch (error) {
        console.error('Błąd podczas pobierania zamówień:', error);
      }
    },
    async loadInvoices() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.email) {
        this.isLoading = true;
        try{
          if (this.orderIDs.length === 0) {
            await this.fetchOrders(user.email);
          }
          let storedInvoices = JSON.parse(localStorage.getItem('invoices'));
          this.totalOrderCount = localStorage.getItem('totalOrderCount');

          const ordersToLoad = this.orderIDs.slice(this.loadedOrderCount, this.loadedOrderCount + 20);
          const csrfToken = localStorage.getItem('csrfToken');
          for (const order_id of ordersToLoad) {
            if (!storedInvoices[order_id].invoice) {
              const response = await axios.get(`https://fakturytayma.pl:3000/api/invoices/order/${order_id}/invoices`, {
                headers: {
                  'X-XSRF-TOKEN': csrfToken
                },
                withCredentials: true
              });
              console.log("Invoices:",JSON.stringify(response.data))
              const orderInvoices = response.data;
              for (const invoice of orderInvoices) {
                if (invoice.type === 'Korekta') {
                  invoice.isPaid = true;
                } else {
                  const { data } = await axios.get(
                    `https://fakturytayma.pl:3000/api/invoices/${invoice.id}`,{
                    headers: {
                      'X-XSRF-TOKEN': csrfToken
                    },
                    withCredentials: true
                  })
                  invoice.isPaid = data.isPaid;
                }
                storedInvoices[order_id].invoice = invoice;
              }
            }
          }
          this.loadedOrderCount += ordersToLoad.length;
          for (const key in storedInvoices) {
            if (storedInvoices[key].invoice && storedInvoices[key].invoice.id) {
              this.displayedInvoices[key] = storedInvoices[key];
            }
          }
          localStorage.setItem('loadedOrderCount',20)
          localStorage.setItem('invoices', JSON.stringify(storedInvoices));
          this.applyFilter();
        } catch (error) {
          console.log(error)
          this.error = 'Błąd podczas ładowania listy faktur.';
        } finally {
          this.isLoading = false;
        }
      } else {
        this.error = 'Brak zalogowanego użytkownika.';
      }
    },
    async refreshInvoices() {
      this.clearInvoices(); // Czyści obecne faktury i ustawienia
      await this.loadInvoices(); // Ładuje faktury ponownie
    },
    applyFilter() {
      let filteredInvoices = Object.values(this.displayedInvoices);
      let filteredInvoicesKeys = Object.keys(this.displayedInvoices);
      if (this.orderSearch) {
        filteredInvoices = filteredInvoices.filter((element, index) =>
          element.id.toString().includes(this.orderSearch) ||
          filteredInvoicesKeys[index].toString().includes(this.orderSearch)
        );
      }
      if (this.numberSearch) {
        filteredInvoices = filteredInvoices.filter(element =>
          element.invoice.number.toString().includes(this.numberSearch)
        );
      }
      if (this.invoiceType !== 'all') {
        filteredInvoices = filteredInvoices.filter(element => element.invoice.type.toLowerCase() === this.invoiceType.toLowerCase());
      }
      if (this.paidFilter !== 'all') {
        filteredInvoices = filteredInvoices.filter(element =>
          this.paidFilter === 'paid' ? element.invoice.isPaid : !element.invoice.isPaid
        );
      }
      if (this.overdueFilter === 'overdue') {
        filteredInvoices = filteredInvoices.filter(element => this.isOverdue(element.invoice));
      }
      filteredInvoices = filteredInvoices.sort((a, b) => {
        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split('-');
          return new Date(`${year}-${month}-${day}`);
        };
        return this.dateFilter === 'newest'
          ? parseDate(b.invoice.issueDate) - parseDate(a.invoice.issueDate)
          : parseDate(a.invoice.issueDate) - parseDate(b.invoice.issueDate);
      });
      this.paginatedInvoices = filteredInvoices;
    },
    async loadMoreInvoices() {
      if (this.loadMoreTimer > 0 || this.isLoading) return;
      this.isLoading = true;
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.email) {
          let storedInvoices = JSON.parse(localStorage.getItem('invoices'));
          const ordersToLoad = this.orderIDs.slice(this.loadedOrderCount, this.loadedOrderCount + 20);
          if (ordersToLoad.length === 0) {
            alert("Brak faktur do załadowania!");
            return;
          }
          const csrfToken = localStorage.getItem('csrfToken');
          for (const order_id of ordersToLoad) {
            if (!storedInvoices[order_id].invoice) {
              const response = await axios.get(`https://fakturytayma.pl:3000/api/invoices/order/${order_id}/invoices`, {
                headers: {
                  'X-XSRF-TOKEN': csrfToken
                },
                withCredentials: true
              });
              const orderInvoices = response.data;
              for (const invoice of orderInvoices) {
                if (invoice.type === 'Korekta') {
                  invoice.isPaid = true;
                } else {
                  const { data } = await axios.get(
                  `https://fakturytayma.pl:3000/api/invoices/${invoice.id}`,
                  {},
                  {
                    headers: {
                      'X-XSRF-TOKEN': csrfToken // Dodaj token CSRF do nagłówka
                    },
                    withCredentials: true // Włącz obsługę ciasteczek
                  }
                )
                  invoice.isPaid = data.isPaid;
                }
                storedInvoices[order_id].invoice = invoice;
              }
            }
          }
          this.loadedOrderCount += ordersToLoad.length;
          localStorage.setItem('loadedOrderCount', this.loadedOrderCount);
          localStorage.setItem('invoices', JSON.stringify(storedInvoices));
          for (const key in storedInvoices) {
            if (storedInvoices[key].invoice && storedInvoices[key].invoice.id) {
              this.displayedInvoices[key] = storedInvoices[key];
            }
          }
          this.applyFilter();
        } else {
          this.error = 'Brak zalogowanego użytkownika.';
        }
      } catch (error) {
        this.error = 'Błąd podczas ładowania kolejnych faktur.';
      } finally {
        this.isLoading = false;
      }
      this.startTimer();
    },
    startTimer() {
      this.loadMoreTimer = 30; // 30 seconds
      const interval = setInterval(() => {
        this.loadMoreTimer -= 1;
        if (this.loadMoreTimer <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async downloadInvoice(element) {
      try {
        const response = await axios.get(`https://fakturytayma.pl:3000/api/invoices/${element.invoice.id}/download`, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${element.invoice.number}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Błąd podczas pobierania faktury:', error);
      }
    },
    async updateInvoiceStatus(element) {
      try {
        const csrfToken = localStorage.getItem('csrfToken');
        const endpoint = element.invoice.isPaid
          ? `https://fakturytayma.pl:3000/api/invoices/${element.invoice.id}/pay`
          : `https://fakturytayma.pl:3000/api/invoices/${element.invoice.id}/unpay`;
        await axios.post(endpoint, {}, {
          headers: {
            'X-XSRF-TOKEN': csrfToken
          },
          withCredentials: true
        });
        let invoices = JSON.parse(localStorage.getItem('invoices'));
        for (let key in invoices) {
          if (invoices[key].id === element.id) {
            console.log("znalazłem fakturę dla której zmienić dane:", JSON.stringify(invoices[key]))
            invoices[key].invoice.isPaid = element.invoice.isPaid;
            break;
          }
        }
        localStorage.setItem('invoices', JSON.stringify(invoices));
        this.applyFilter();
      } catch (error) {
        console.error('Błąd podczas zmiany statusu faktury:', error);
      }
    },
    viewDetails(invoice) {
      this.selectedInvoice = invoice;
      this.showModal();
    },
    showModal() {
      const modal = new Modal(document.getElementById('invoiceModal'));
      modal.show();
    },
    isOverdue(invoice) {
      const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split('-');
            return new Date(`${year}-${month}-${day}`);
        };
      const dueDate = new Date(parseDate(invoice.dueDate));
      const currentDate = new Date();
      return !invoice.isPaid && dueDate < currentDate;
    },
    clearInvoices() {
      localStorage.removeItem('invoices');
      localStorage.removeItem('totalOrderCount');
      localStorage.removeItem('loadedOrderCount');
      this.displayedInvoices = {};
      this.loadedOrderCount = 0;
      this.totalOrderCount = 0;
      this.orderIDs = [];
    }
  },
  created() {
    const storedInvoices = JSON.parse(localStorage.getItem('invoices'));
    if (storedInvoices) {
      for (const key in storedInvoices) {
        if (storedInvoices[key].invoice && storedInvoices[key].invoice.id) {
          this.displayedInvoices[key] = storedInvoices[key];
        }
      }
      this.applyFilter();
    } else {
      this.loadInvoices();
    }

    // Listen for logout event
    EventBus.on('logout', this.clearInvoices);
  },
  beforeUnmount() {
    // Clean up event listener
    EventBus.off('logout', this.clearInvoices);
  },
  watch: {
    displayedInvoices: {
      handler(newInvoices) {
        console.log('Displayed Invoices:', newInvoices);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.invoice-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
}
.filters {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-item label {
  margin-bottom: 5px;
}
.loading,
.error {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.table-container {
  flex: 1;
  overflow: auto;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.table th {
  background-color: #f2f2f2;
}
.table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.table tr:hover {
  background-color: #f1f1f1;
}
.btn-details,
.btn-download {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 20px;
  cursor: pointer;
  border-radius: 4px;
}
.btn-details {
  background-color: #2196F3;
}
.btn-download {
  background-color: #f44336;
}
.btn-download i {
  margin-right: 5px;
}
.btn-refresh {
  float: right; /* Align to the right */
  padding: 6px 12px;
  margin-bottom: 20px; /* Spacing below the button */
  width: 150px; /* Set a fixed width */
  text-align: center; /* Center text within the button */
}
.overdue {
  background-color: #ffcccc;
}
.overdue-icon {
  color: red;
  margin-left: 10px;
  animation: pulse 1.5s infinite;
  font-weight: bold;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}
select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
select.paid {
  background-color: #d4edda;
  color: #155724;
}
select.unpaid {
  background-color: #f8d7da;
  color: #721c24;
}
.timer {
  text-align: center;
  margin: 10px 0;
}
</style>
