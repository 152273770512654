import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/AppHome.vue';
import AccountDetails from '../components/AccountDetails.vue';
import AppLogin from '../components/AppLogin.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/account-details',
    name: 'AccountDetails',
    component: AccountDetails
  },
  {
    path: '/login',
    name: 'AppLogin',
    component: AppLogin
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = document.cookie.includes('loggedIn=true');
  if (to.name !== 'AppLogin' && !isLoggedIn) next({ name: 'AppLogin' });
  else next();
});

export default router;
