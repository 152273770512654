<template>
  <footer class="footer">
    <p>&copy; 2024 Tayma Sp. z o.o.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style>
.footer {
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
}
</style>
