<template>
  <div class="login-page">
    <div class="login-box">
      <img src="/tayma.png" alt="Logo" class="logo">
      <h2 style="margin-bottom: 30px;">Panel Faktur Tayma</h2>

      <div v-if="errorMessage" class="alert alert-danger alert-custom" role="alert">
        {{ errorMessage }}
      </div>

      <form @submit.prevent="login">
        <div role="group" class="form-group d-flex align-items-center">
          <label class="form-label me-3" for="email">E-mail</label>
          <div class="flex-grow-1">
            <input
              id="email"
              v-model="email"
              type="email"
              :class="{'is-invalid': emailTouched && !emailState, 'form-control': true}"
              @blur="emailTouched = true"
              placeholder="Wprowadź e-mail"
              required
            />
            <div class="invalid-feedback" v-if="emailTouched && !emailState">
              Podaj prawidłowy e-mail zawierający @.
            </div>
          </div>
        </div>

        <div role="group" class="form-group d-flex align-items-center">
          <label class="form-label me-3" for="password">Hasło</label>
          <div class="flex-grow-1">
            <input
              id="password"
              v-model="password"
              type="password"
              :class="{'is-invalid': passwordTouched && !passwordState, 'form-control': true}"
              @blur="passwordTouched = true"
              placeholder="Wprowadź hasło"
              required
            />
            <div class="invalid-feedback" v-if="passwordTouched && !passwordState">
              Hasło musi mieć co najmniej 5 znaków.
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Zaloguj</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/bus';

export default {
  data() {
    return {
      email: '',
      password: '',
      emailTouched: false,
      passwordTouched: false,
      errorMessage: ''
    };
  },
  computed: {
    emailState() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return this.emailTouched ? emailRegex.test(this.email) : null;
    },
    passwordState() {
      return this.passwordTouched ? this.password.length > 4 : null;
    }
  },
  methods: {
    async login() {
      // Upewnij się, że pola są oznaczone jako dotknięte, aby wymusić walidację
      this.emailTouched = true;
      this.passwordTouched = true;

      if (!this.emailState) {
        this.errorMessage = 'Podaj prawidłowy e-mail zawierający @.';
        return;
      }

      if (!this.passwordState) {
        this.errorMessage = 'Hasło musi mieć co najmniej 5 znaków.';
        return;
      }

      try {
        // Najpierw pozyskaj token CSRF
        const csrfResponse = await axios.get('https://fakturytayma.pl:3000/api/csrf-token', {
          withCredentials: true // To enable sending and receiving cookies
        });

        const csrfToken = csrfResponse.data.csrfToken;
        localStorage.setItem('csrfToken', csrfToken);

        // Następnie wykonaj logowanie
        const response = await axios.post('https://fakturytayma.pl:3000/api/login', {
          email: this.email,
          password: this.password
        }, {
          withCredentials: true, // To enable sending and receiving cookies
          headers: {
            'X-XSRF-TOKEN': csrfToken // Użyj pozyskanego tokena CSRF
          }
        });

        console.log('Server response:', response);

        if (response.data.success) {
          console.log('Login successful:', response.data.user);
          document.cookie = "loggedIn=true;path=/";
          localStorage.setItem('user', JSON.stringify(response.data.user));
          EventBus.emit('login', response.data.user);
          this.$router.push('/');
        } else {
          this.errorMessage = 'Błędny email lub hasło, spróbuj jeszcze raz! Jeśli zapomniałeś hasła, skontaktuj się z naszym zespołem.';
        }
      } catch (error) {
        console.error('Server error:', error);
        this.errorMessage = 'Błąd serwera. Spróbuj ponownie później.';
      }
    }
  }
};
</script>

<style>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.logo {
  max-width: 250px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-label {
  min-width: 120px;
  text-align: right;
}

form button {
  background: #007bff;
  color: white;
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form button:hover {
  background: #0056b3;
}

.alert-custom {
  width: 100%;
  word-wrap: break-word;
}
</style>